<template>
    <div class="container-fluid p-4">

        <div class="row">
            <div class="col mb-4">
                <div class="card card-contrast text-center">
                    <div class="card-header card-header-sm">Featured</div>
                    <div class="card-body py-2">
                        <div class="card-title">Special title treatment</div>
                        <p>With supporting text below as natural lead-in to additional content</p><a
                        class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                    <div class="card-footer card-footer-sm text-muted">2 days ago</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col mb-4">
                <div class="card mb-3"><img alt="Placeholder"
                                            class="card-img-top"
                                            src="https://via.placeholder.com/480x220.png?text=Image+Cap">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus
                            odio.</p><a class="btn btn-primary" href="#">Button</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card mb-3"><img alt="Placeholder"
                                            class="card-img-top"
                                            src="https://via.placeholder.com/480x220.png?text=Image+Cap">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus
                            odio.</p><a class="card-link" href="#">Card link</a><a class="card-link" href="#">Another
                        link</a>
                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div class="card"><img alt="Placeholder" class="card-img-top"
                                       src="https://via.placeholder.com/480x220.png?text=Image+Cap">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p class="mb-0">Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac
                            tempus odio.</p>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"></li>
                        <li class="list-group-item">An Item</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue
                            odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Button</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card text-center">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue
                            odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Button</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card text-right">
                    <div class="card-body p-3">
                        <div class="card-title">Card Title</div>
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue
                            odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Button</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Cards',
}
</script>
